import introJs from "intro.js";
import {
  createState,
  createResourceState,
  createEffect,
  afterEffects,
} from "solid-js";

import logo from "./highlighter.svg";

const RenderForEachTimeline = ({ source }) => {
  const [state, setState] = createState({ res: source });

  // setInterval(() => {
  //   setState({ count: state.count + 1 });
  // }, 1000);

  // createEffect(() => {
  //   // introJs().start();
  //   console.log("res", state.res);
  // });

  // setTimeout(() => {
  //   // introJs().start();
  //   console.log("intro js started in timeout");
  // }, 5);
  // afterEffects(() => {
  //   // introJs().start();
  //   console.log("intro js started in after effects");
  // });

  return (
    <div class="timeline is-centered">
      <header class="timeline-header animate__animated animate__fadeInDown">
        <span class="tag is-small is-info">Highlights</span>
      </header>

      <div class="timeline-item is-warning highlight_red_box" style="cursor: pointer;" onClick={() => {
                    introJs().start();
                  }}>
        <div class="timeline-marker is-flex has-background-light is-image is-48x48 highlight_red_box">
          <img src={logo} class="highlighter_svg"  alt="highlighter"/>
        </div>
        <div class="timeline-content highlight_red_box">
          <p class="heading">Walk through project highlights</p>
          <p class="content is-small">Click me </p>
        </div>
      </div>
      <For each={source.projects}>
        {(project, index) => (
          <>
            <header class="timeline-header animate__animated animate__fadeInDown">
              <Show when={project.job_order === 1}>
                <span
                  class="tag is-large is-primary">
                  {project.job_period}
                </span>
              </Show>
              <Show
                when={project.job_order !== 1 && project.job_type === "work"}
              >
                <span class="tag is-medium">{project.job_period}</span>
              </Show>
              <Show
                when={project.job_order !== 1 && project.job_type === "school"}
              >
                <span class="tag is-medium is-danger">
                  {project.job_period}
                </span>
              </Show>
            </header>
            <div class="timeline-item is-warning">
              <div class="timeline-marker is-primary"></div>
              <div
                class="timeline-content"
                data-step={index() + 1}
                data-intro={project.role + " from " + project.job_period}
              >
                <p class="heading is-large has-text-black is-size-6 has-text-black animate__animated animate__fadeInRight animate__delay-2s">
                  {project.role}
                </p>
                <p class="heading">{project.job_location[0].text}</p>
                <p class="content is-small">
                  {project.job_description[0].text}
                </p>
              </div>
            </div>
          </>
        )}
      </For>
      <div class="timeline-header">
        <span class="tag is-medium is-primary">Start</span>
      </div>
    </div>
  );
};

export default RenderForEachTimeline;

import { createState, createSignal, createEffect, createMemo } from "solid-js";
import anime from "animejs";
import "./words.scss";
const MovingWords = ({message}) => {
  // const [state, setState] = createState({ name: "Solid", bold: true , message: "MONDAY"});
  // console.log('message',message)
  createEffect(() => {
    // console.log("hello moving words", message);
    setTimeout(() => {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".ml1 .letters");
      // console.log('textWrapper.textContent', textWrapper.textContent)
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );

      // setState("message", "ANIMATE JS")

      anime
        .timeline({ loop: true })
        .add({
          targets: ".ml1 .letter",
          scale: [0.3, 1],
          opacity: [0, 1],
          translateZ: 0,
          color: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 255)}, .6)`,
          // color: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)',
          // background: `rgba(255, 255, 255, .9)`,
          // color: "red",
          easing: "easeOutExpo",
          duration: 600,
          delay: (el, i) => 70 * (i + 1),
        })
        .add({
          targets: ".ml1 .line",
          scaleX: [0, 1],
          opacity: [0.5, 1],
          easing: "easeOutExpo",
          // background: `rgba(${Math.floor(Math.random() * 220)}, ${Math.floor(Math.random() * 250)}, ${Math.floor(Math.random() * 100)}, .9)`,
          duration: 700,
          offset: "-=875",
          delay: (el, i, l) => 80 * (l - i),
        })
        .add({
          targets: ".ml1",
          opacity: 0,
          duration: 1000,
          // background: 'rgba(255, 255, 255, .9)',
          easing: "easeOutExpo",
          delay: 3000,
        });
    }, 300);
  });

  return (
    <div class="ml1">
      <span class="text-wrapper has-text-grey-light">
        <span class="line line1"></span>
        <span class="letters">{message}</span>
        <span class="line line2"></span>
      </span>
    </div>
  );
};

export default MovingWords;

import _ from "lodash";
import introJs from "intro.js";

// import RenderForEachCard from "./RenderForEachCard";
import RenderForEachTimeline from "./RenderForEachTimeline";
import LoadSpinner from "./LoadSpinner";
import StaySpinner from "./StaySpinner";
import Spinner3D from "./Spinner3D";


const ProjectsPage = ({ resource }) => {
  // setTimeout(()=> {
  //   introJs().start();
  // },5000);

return (
    <SuspenseList revealOrder="forwards" tail="collapsed">
      <Suspense fallback={<LoadSpinner />}>
        <StaySpinner/>
        <Spinner3D/>
        
        {/* <div class="cssload-spinner"></div> */}
        {/* {JSON.stringify(_.keys(resource.projects))} */}
        <RenderForEachTimeline source={resource} />
        {/* <RenderForEachCard source={resource} /> */}
      </Suspense>
    </SuspenseList>
);
};

  export default ProjectsPage;
const Spinner3D = () => (
  <div class="cssload-wrap highlight_red_box" data-step="3" data-intro='step 3!'>
    <div class="cssload-circle"></div>
    <div class="cssload-circle"></div>
    <div class="cssload-circle"></div>
    <div class="cssload-circle"></div>
    <div class="cssload-circle"></div>
  </div>
);

export default Spinner3D;

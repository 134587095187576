import { createResourceState, createEffect } from "solid-js";
import PrismicConfig from "./prismic-configuration";
import Prismic from "prismic-javascript";
import * as R from "ramda";
// import _ from "lodash";
const apiEndpoint = "https://bytehelps.prismic.io/api/v2";

export function getProjects() {
  let loading;
  const [state, load] = createResourceState();
  load({
    // user: fetchUser(),
    // posts: fetchPosts(),
    // trivia: fetchTrivia()
    projects: fetchProjects()
  });
  // console.log('state', state,'fetchProjects()', fetchProjects())
  return state;
}

// createEffect(() => {
//   // const userId = state.userId;
//     loading = load({
//       projects: fetchProjects()
//     });
//   console.log('createEffect: res in CMS API ...',state.projects);
// });

function fetchProjects() {
    // console.log("fetch projects..1..via cmsApi");
    let projects = [];
    return new Promise(resolve => {

        Prismic.api(apiEndpoint, PrismicConfig.accessToken).then((api) => {
            api
              .query(Prismic.Predicates.at("document.type", "project"))
              .then((response) => {
                // console.log('prismic.io response=' + JSON.stringify(response.results[0], null, 4));
                let rawList = response.results;
                if (rawList) {
                  projects = R.map((o) => {
                    return {
                      role: o.data.role,
                      job_order: o.data.job_order,
                      job_type: o.data.job_type,
                      job_description: o.data.job_description,
                      job_period: o.data.job_period,
                      job_location: o.data.job_location,
                    };
                  }, rawList);
                  projects = R.sortBy(R.prop("job_order"), projects);
                }
                // console.log('get projects', projects)
                // projects = _.slice(projects,1,2);

                setTimeout(()=> {
                  // console.log("fetched projects ..2..");
                  resolve(projects);

                },3000);
                // resolve(projects);
        
                // this.setState({
                //   content: {
                //     headline: 'BYTEHELPS',
                //     projects: projects
                //   }
                // })
              });
          });
    //   setTimeout(() => {
    //     console.log("fetched user");
    //     resolve({
    //       name: "Ringo Starr"
    //     });
    //   }, 500);
    });
  }
  
import CoreSpinner from "./CoreSpinner";

const LoadSpinner = () => (
  // <div class="spinner"></div>
  // <div id="spinner">
  // <div class="lds-hourglass highlight_red_box"></div>
  // </div>
  <div id="cssload-wrapper" class="my-cssload-wrapper">
    <CoreSpinner />
  </div>
);

export default LoadSpinner;

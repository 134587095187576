const CoreSpinner = () => (
      <div class="cssload-loader">
        <div class="cssload-line"></div>
        <div class="cssload-line"></div>
        <div class="cssload-line"></div>
        <div class="cssload-line"></div>
        <div class="cssload-line"></div>
        <div class="cssload-line"></div>
        <div class="cssload-subline"></div>
        <div class="cssload-subline"></div>
        <div class="cssload-subline"></div>
        <div class="cssload-subline"></div>
        <div class="cssload-subline"></div>
        <div class="cssload-loader-circle-1">
          <div class="cssload-loader-circle-2"></div>
        </div>
        <div class="cssload-needle"></div>
        <div class="cssload-loading">BYTEHELPS</div>
      </div>
  );
  
  export default CoreSpinner;
  
// import { createState, createResourceState, createEffect } from "solid-js";
import { render } from "solid-js/dom";

// import { Switch, Match } from "solid-js/dom";

// import { createResourceState, createEffect } from "solid-js";
import {
  createState,
  createResourceState,
  createEffect,
  afterEffects,
  onCleanup,
  Component,
} from "solid-js";

// import { styled } from "solid-styled-components";

// import PrismicConfig from "./api/prismic-configuration";
// import Prismic from "prismic-javascript";
// import * as R from "ramda";

// import moment from "moment";
import _ from "lodash";
import introJs from "intro.js";
import "./styles/overlay.scss";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import "./app.scss";

// import "bulma/sass/utilities/_all.sass"
// import "bulma/sass/elements/button.sass"
// import "bulma/css/bulma.css";
// import "bulma/bulma.sass";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css";
// import "bulma-extensions/src/sass/index.sass";

import "animate.css";

// import TimelineDemo from "./components/TimelineDemo";
import ProjectsPage from "./components/ProjectsPage";
import MovingWords from "./animation/MovingWords";
// import { lazy } from "solid-js";
// const LazyMovingWords = lazy(() => import("./animation/MovingWords"));
import { getProjects } from "./api/cmsApi";
// import { fetchProfileData } from "./api/fakeApi";
// import "./app.scss";

// const Div = styled("div")`
//   color: red;
//   font-size: 32px;
//   padding: 5px;
//   border: 2px solid black;
//   background-color: purple;
// `;

// const StyledDiv = styled(Div)`
//   background-color: lightblue;
//   font-weight: ${(props) => (props.bold ? "bold" : 100)};
// `;

const App = () => {
  const resource = getProjects(0);
  // console.log("get projects in App ..3..");
  // const [state, setState] = createState({ res: resource});
  const [state, setState] = createState({
    name: "Solid",
    bold: true,
    res: resource,
  });

  // setTimeout(()=> {
  //   console.log("get projects in App ..4..");
  //   // introJs().start();
  //   setState({res: state.res+' updated'})
  // },1000);

  // setInterval(()=> {
  //   console.log("get projects in App ..5..");
  //   // introJs().start();
  //   setState({res: state.res+' updated 5'})
  // },1000);

  // createEffect(() => {
  //   console.log('createEffect: res in APP ...',state);
  // });

  // const [state, setState] = createState({ count: 0 }),
  // timer = setInterval(() => setState("count", c => c + 1), 1000);
  //  onCleanup(() => clearInterval(timer));
  // createEffect(()=> {
  //   introJs().start();
  // });

  createEffect(() => {
    // console.log('createEffect: res in APP ...',state.name);
  });

  return (
    <div>
      {/* <section class="section">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-one-third is-offset-one-quarter">
              <button
                class="button is-primary"
                onClick={() => {
                  console.log('click button');
                  introJs().start();
                  setState("name", (name) => 'hello')
                }
              }
                bold={state.bold}
              >
                Primary button 1{state.name} 
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <section class="section2 highlight_red_box">
        <div class="container highlight_red_box">
          <div class="columns">
            <div
              class="column is-6 is-offset-4 highlight_red_box"
              data-step="1"
              data-intro="step one!"
            >
              <MovingWords message={"BYTEHELPS 2021"} />
            </div>
          </div>
        </div>
      </section>
      <section class="section highlight_blue_box">
        <div class="container">
          <div class="columns">
            <div class="column">
              {/* <ProjectsPage resource={state.res} /> */}
              <Suspense fallback={<h1>Loading...for suspense</h1>}>
                <ProjectsPage resource={state.res} />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="section">
        <div class="container highlight_red_box">
          <div class="columns is-vcentered">
            <div class="column is-one-third is-offset-one-quarter">
              <TimelineDemo />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

render(App, document.getElementById("app"));

import CoreSpinner from "./CoreSpinner";
import { createEffect } from "solid-js";
import anime from "animejs";

const StaySpinner = () => {
  // <div class="spinner"></div>
  // <div id="spinner">
  // <div class="lds-hourglass highlight_red_box"></div>
  // </div>
  // createEffect(() => {
    setTimeout(() => {
      console.log('animating stay spinner')
      anime
        .timeline({loop:false})
        .add({
          targets: ".cssstay-animatejs",
          height: "1000px",
          easing: 'spring(1, 80, 10, 0)',

          duration: 2000
        })
        .add({
        targets: ".cssstay-animatejs",
        // border: "5px solid red",
      
        right: "500px",
        easing: 'linear',
        // height: "1000px !important",
        // translateX: 2050,
        duration: 3000
      })
      
    }, 5000);
  // });
  return (
    <div
      id="cssstay-wrapper"
      class="cssstay-animatejs"
    >
      <CoreSpinner />
    </div>
  );
};

export default StaySpinner;

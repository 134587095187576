export default {

    // apiEndpoint: 'https://your-repo-name.prismic.io/api/v2',
    apiEndpoint: 'https://bytehelps.prismic.io/api',
  
    // -- Access token if the Master is not open
    accessToken: 'MC5XZzBGcHg0QUFGMGZVYTdG.EU5rHxXvv73vv70o77-9We-_ve-_ve-_vQlfT--_ve-_vQEjXO-_ve-_ve-_ve-_vVPvv73vv73vv70M77-9Iw',
  
  
    // OAuth
    // clientId: 'xxxxxx',
    // clientSecret: 'xxxxxx',
  
    // -- Links resolution rules
    // This function will be used to generate links to Prismic.io documents
    // As your project grows, you should update this function according to your routes
    linkResolver(doc, ctx) {
      return '/';
    },
  };
  